<template>
  <button v-if="buttonType == 0" type="button" class="btn btn-primary" @click="showModal()"><font-awesome-icon icon="plus" /> Erstellen</button>
  <button v-else-if="buttonType == 1" type="button" class="btn btn-sm btn-primary" @click="showModal()"><font-awesome-icon icon="pen" /></button>
  <button v-else type="button" class="btn btn-primary" @click="showModal()">Variable</button>

  <!-- Create Varaible Modal -->
  <div class="modal fade" :id="'editVaraibleModal-' + index" tabindex="-1" aria-labelledby="editVaraibleModal" aria-hidden="true" ref="editVaraibleModalRef">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header bg-primary py-2">
          <span v-if="editVariable.id == 0" class="modal-title my-auto" id="editVaraibleModalLabel"> Neue Variable </span>
          <span v-else class="modal-title my-auto" id="editVaraibleModalLabel"> Variable bearbeiten </span>

          <button type="button" class="btn-close btn-sm text-white" @click="closeModal()"></button>
        </div>

        <div class="modal-body" id="editVaraibleModalBody">
          <LoadingSpinner v-if="loading" text="... Daten werden geladen ..." />

          <form v-else @submit.prevent="saveVariable">
            <div class="mb-3">
              <label :for="'variableTitel-' + index" class="form-label fw-bold">Titel</label>
              <input type="text" class="form-control" :id="'variableTitel-' + index" v-model="editVariable.titel" />
            </div>

            <div class="mb-3">
              <label :for="'variableVariable-' + index" class="form-label fw-bold">Variable</label>
              <input type="text" class="form-control" :id="'variableVariable-' + index" v-model="variableKey" disabled />
            </div>

            <div class="mb-3">
              <label :for="'variableHerkunft-' + index" class="form-label fw-bold">Herkunft</label>
              <select class="form-select" :id="'variableHerkunft-' + index" v-model="editVariable.herkunft" @change="resetFeld()">
                <option value="">Keine...</option>
                <option v-for="(table, index) in herkunft" :key="index" :value="table.table">{{ table.table }}</option>
              </select>
            </div>

            <div class="mb-3">
              <label :for="'variableFeld-' + index" class="form-label fw-bold">Feld</label>
              <select class="form-select" :id="'variableFeld-' + index" v-model="editVariable.feld" @change="setType()">
                <option value="">Keine Feld</option>
                <option
                  v-for="(el, index) in herkunft.find((t) => t.table == editVariable.herkunft)?.fields.sort((a, b) => a.titel.localeCompare(b.titel))"
                  :key="index"
                  :value="el.feld"
                >
                  {{ el.titel }}
                </option>
              </select>
            </div>

            <div class="mb-3">
              <label :for="'variableType-' + index" class="form-label fw-bold">Type</label>
              <select class="form-select" :id="'variableType-' + index" v-model="editVariable.type">
                <!-- <option value="">Keine Type</option> -->
                <option value="text">Text</option>
                <!-- <option value="number">Zahl</option> -->
                <option value="date">Datum</option>
              </select>
            </div>

            <div v-if="editVariable.type == 'text'">
              <div class="mb-3">
                <label :for="'variableValue-' + index" class="form-label fw-bold">Standardwert</label>
                <input type="text" class="form-control" :id="'variableValue-' + index" v-model="variable.value" />
              </div>

              <hr class="my-3" />

              <h5>Eingabeüberprüfung</h5>

              <div class="d-flex mb-4">
                <div class="form-check me-3">
                  <input
                    class="form-check-input"
                    type="radio"
                    :name="'input-text-' + index"
                    :id="'input-text-' + index"
                    value="text"
                    v-model="editVariable.validationType"
                  />
                  <label class="form-check-label" :for="'input-text-' + index"> Text </label>
                </div>

                <div class="form-check me-3">
                  <input
                    class="form-check-input"
                    type="radio"
                    :name="'input-ganzzahlen-' + index"
                    :id="'input-ganzzahlen-' + index"
                    value="int"
                    v-model="editVariable.validationType"
                  />
                  <label class="form-check-label" :for="'input-ganzzahlen-' + index"> Ganzzahlen </label>
                </div>

                <div class="form-check me-3">
                  <input
                    class="form-check-input"
                    type="radio"
                    :name="'input-kommazahlen-' + index"
                    :id="'input-kommazahlen-' + index"
                    value="float"
                    v-model="editVariable.validationType"
                  />
                  <label class="form-check-label" :for="'input-kommazahlen-' + index"> Kommazahlen </label>
                </div>

                <div class="form-check me-3">
                  <input
                    class="form-check-input"
                    type="radio"
                    :name="'input-currency-' + index"
                    :id="'input-currency-' + index"
                    value="currency"
                    v-model="editVariable.validationType"
                  />
                  <label class="form-check-label" :for="'input-currency-' + index"> Währung </label>
                </div>
              </div>

              <div v-if="editVariable.validationType == 'text'">
                <p class="fw-bold">Eingabelänge</p>
                <div class="row mb-4">
                  <div class="col-3">
                    <label :for="'input-min-' + index" class="form-label">Min</label>
                    <input type="number" class="form-control" :id="'input-min-' + index" min="0" max="80" v-model="editVariable.minLength" />
                  </div>

                  <div class="col-3 offset-1">
                    <label :for="'input-max-' + index" class="form-label">Max <small>(maximal 80 Zeichen)</small></label>
                    <input type="number" class="form-control" :id="'input-max-' + index" min="0" max="80" v-model="editVariable.maxLength" />
                  </div>
                </div>
              </div>

              <TextInput
                :index="0"
                :modelValue="testInput"
                label="Test Input"
                :min="editVariable.minLength"
                :max="editVariable.maxLength"
                :inputType="editVariable.validationType"
              />
            </div>

            <!-- <pre>{{ editVariable }}</pre> -->

            <hr class="my-3" />

            <div class="d-flex justify-content-end">
              <button type="button" class="btn btn-secondary me-3" @click="closeModal()">Abbrechen</button>

              <button type="submit" class="btn btn-primary me-3">Speichern</button>
            </div>
          </form>

          <div v-if="!loading && editVariable.id != 0">
            <hr class="my-3" />
            <button type="button" class="btn btn-danger" @click="deleteVariable()"><font-awesome-icon icon="trash" /> Variable löschen</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType, computed, ref } from 'vue';
  import { Modal } from 'bootstrap';
  import { useStore } from 'vuex';

  import { ActionTypes as VariablenActionTypes } from '@/store/modules/Variablen/actions';

  import { Variable } from '@/models/VorlageModels';

  import TextInput from '../FormInputs/TextInput.vue';
  import LoadingSpinner from '../LoadingSpinner.vue';

  export default defineComponent({
    name: 'CreateVariableModal',

    components: {
      TextInput,
      LoadingSpinner,
    },

    props: {
      index: {
        type: Number,
        default: 0,
      },
      buttonType: {
        type: Number,
        default: 0,
      },
      variable: {
        type: Object as PropType<Variable>,
        default: () => new Variable(),
      },
    },

    emits: ['delete-variable'],

    setup(props, { emit }) {
      const store = useStore();

      const loading = computed(() => store.getters.status.createVariablenLoading || store.getters.status.updateVariablenLoading);
      const errorMsg = computed(() => store.getters.status.variablenErrorMsg);

      const testInput = ref('');

      const herkunft = [
        {
          table: 'Personaldaten',
          fields: [
            {
              titel: 'Vorname',
              feld: 'vorname',
              type: 'text',
            },
            {
              titel: 'Name',
              feld: 'name',
              type: 'text',
            },
            {
              titel: 'Geburtsdatum',
              feld: 'gebdat',
              type: 'date',
            },
            {
              titel: 'GUID',
              feld: 'guid',
              type: 'text',
            },
            {
              titel: 'Straße',
              feld: 'strasse',
              type: 'text',
            },
            {
              titel: 'PLZ',
              feld: 'plz',
              type: 'text',
            },
            {
              titel: 'Ort',
              feld: 'ort',
              type: 'text',
            },
            {
              titel: 'Land',
              feld: 'land',
              type: 'text',
            },
            {
              titel: 'Telefon',
              feld: 'tel',
              type: 'text',
            },
            {
              titel: 'Fax',
              feld: 'fax',
              type: 'text',
            },
            {
              titel: 'Mobil',
              feld: 'mobil',
              type: 'text',
            },
            {
              titel: 'E-Mail',
              feld: 'email',
              type: 'text',
            },
            {
              titel: 'Telefon geschäftlich',
              feld: 'telg',
              type: 'text',
            },
            {
              titel: 'Fax geschäftlich',
              feld: 'faxg',
              type: 'text',
            },
            {
              titel: 'Mobil geschäftlich',
              feld: 'mobilg',
              type: 'text',
            },
            {
              titel: 'E-Mail geschäftlich',
              feld: 'emailg',
              type: 'text',
            },
            {
              titel: 'Mandant',
              feld: 'mandant',
              type: 'text',
            },
            {
              titel: 'Abrechnungskreis',
              feld: 'abrkreis',
              type: 'text',
            },
            {
              titel: 'PersonalNr.',
              feld: 'persnr',
              type: 'text',
            },
            {
              titel: 'Kostenstelle',
              feld: 'kostenst',
              type: 'text',
            },
            {
              titel: 'Krankenkasse',
              feld: 'krankenkasse',
              type: 'text',
            },
            {
              titel: 'SV-Nummer',
              feld: 'svnummer',
              type: 'text',
            },
            {
              titel: 'Geschlecht',
              feld: 'geschlecht',
              type: 'text',
            },
            {
              titel: 'Titel',
              feld: 'titel',
              type: 'text',
            },
            {
              titel: 'Wochenarbeitsstunden',
              feld: 'wochenarbeitsstunden',
              type: 'text',
            },
            {
              titel: 'Unbezahlte Überstunden',
              feld: 'unbezahlteueberstunden',
              type: 'text',
            },
            {
              titel: 'Jahresurlaub',
              feld: 'jahresurlaub',
              type: 'text',
            },
            {
              titel: 'Pausezeit',
              feld: 'pausezeit',
              type: 'text',
            },
            {
              titel: 'IBAN',
              feld: 'iban',
              type: 'text',
            },
            {
              titel: 'BIC',
              feld: 'bic',
              type: 'text',
            },
            {
              titel: 'Bankname',
              feld: 'bankname',
              type: 'text',
            },
            {
              titel: 'PLZ Zweitwohnsitz',
              feld: 'plz2',
              type: 'text',
            },
            {
              titel: 'Straße Zweitwohnsitz',
              feld: 'strasse2',
              type: 'text',
            },
            {
              titel: 'Ort Zweitwohnsitz',
              feld: 'ort2',
              type: 'text',
            },
            {
              titel: 'Rentenbeginn',
              feld: 'rentenbeginn',
              type: 'date',
            },
            {
              titel: 'Krankenkasse privat',
              feld: 'krankenkasseprivat',
              type: 'text',
            },
            {
              titel: 'Notfallname',
              feld: 'notfallname',
              type: 'text',
            },
            {
              titel: 'Notfalltelefon',
              feld: 'notfalltelefon',
              type: 'text',
            },
            {
              titel: 'Chatadresse',
              feld: 'chatadresse',
              type: 'text',
            },
            {
              titel: 'Jobticket',
              feld: 'jobticket',
              type: 'text',
            },
            {
              titel: 'IDF-Nummer',
              feld: 'idfnummer',
              type: 'text',
            },
            {
              titel: 'Vertrag-Nummer',
              feld: 'vertnr',
              type: 'text',
            },
            {
              titel: 'Vertriebszentrum-Nummer',
              feld: 'vznummer',
              type: 'text',
            },
            {
              titel: 'Sprache',
              feld: 'sprache',
              type: 'text',
            },
          ],
        },
        {
          table: 'Vertrag',
          fields: [
            {
              titel: 'Vertragsbeginn',
              feld: 'vertragsBeginn',
              type: 'date',
            },
            {
              titel: 'Vertragsende',
              feld: 'vertragsEnde',
              type: 'date',
            },
            {
              titel: 'Vertragspartner 1 Unternehmen',
              feld: 'vertragspartei1.unternehmen',
              type: 'text',
            },
            {
              titel: 'Vertragspartner 1 Vorname',
              feld: 'vertragspartei1.vorname',
              type: 'text',
            },
            {
              titel: 'Vertragspartner 1 Name',
              feld: 'vertragspartei1.name',
              type: 'text',
            },
            {
              titel: 'Vertragspartner 1 Strasse',
              feld: 'vertragspartei1.strasse',
              type: 'text',
            },
            {
              titel: 'Vertragspartner 1 PLZ',
              feld: 'vertragspartei1.plz',
              type: 'text',
            },
            {
              titel: 'Vertragspartner 1 Ort',
              feld: 'vertragspartei1.ort',
              type: 'text',
            },
            {
              titel: 'Vertragspartner 1 Land',
              feld: 'vertragspartei1.land',
              type: 'text',
            },
            {
              titel: 'Vertragspartner 2 Unternehmen',
              feld: 'vertragspartei2.unternehmen',
              type: 'text',
            },
            {
              titel: 'Vertragspartner 2 Vorname',
              feld: 'vertragspartei2.vorname',
              type: 'text',
            },
            {
              titel: 'Vertragspartner 2 Name',
              feld: 'vertragspartei2.name',
              type: 'text',
            },
            {
              titel: 'Vertragspartner 2 Strasse',
              feld: 'vertragspartei2.strasse',
              type: 'text',
            },
            {
              titel: 'Vertragspartner 2 PLZ',
              feld: 'vertragspartei2.plz',
              type: 'text',
            },
            {
              titel: 'Vertragspartner 2 Ort',
              feld: 'vertragspartei2.ort',
              type: 'text',
            },
            {
              titel: 'Vertragspartner 2 Land',
              feld: 'vertragspartei2.land',
              type: 'text',
            },
          ],
        },
      ];

      const editVariable = ref<Variable>(JSON.parse(JSON.stringify(props.variable)));
      const variableKey = computed(() => `<<${editVariable.value.titel.trim().toUpperCase()}>>`);

      function resetFeld() {
        editVariable.value.feld = '';
        editVariable.value.type = '';
      }

      function setType() {
        editVariable.value.type =
          herkunft.find((t) => t.table == editVariable.value.herkunft)?.fields.find((f) => f.feld == editVariable.value.feld)?.type || '';
      }

      function clearData() {
        if (props.variable.id == 0) {
          editVariable.value = new Variable();
        } else {
          editVariable.value = JSON.parse(JSON.stringify(props.variable));
        }
        // editVariable.value = new Variable();
      }

      function saveVariable() {
        if (editVariable.value.id == 0) {
          editVariable.value.platzhalter = variableKey.value;
          store.dispatch(VariablenActionTypes.CreateVariable, editVariable.value).then(() => {
            if (errorMsg.value == '') {
              clearData();
              closeModal();
            }
          });
        } else {
          editVariable.value.platzhalter = variableKey.value;
          store.dispatch(VariablenActionTypes.UpdateVariable, editVariable.value).then(() => {
            if (errorMsg.value == '') {
              clearData();
              closeModal();
            }
          });
        }
      }

      function deleteVariable() {
        closeModal();
        emit('delete-variable', editVariable.value.id);
      }

      const editVaraibleModalRef = ref();
      function closeModal() {
        clearData();
        Modal.getInstance(editVaraibleModalRef.value)?.hide();
      }
      function showModal() {
        if (editVaraibleModalRef.value) {
          new Modal(editVaraibleModalRef.value).show();
        }
      }

      return {
        store,
        loading,
        errorMsg,

        testInput,

        herkunft,

        editVariable,
        variableKey,

        resetFeld,
        setType,
        saveVariable,
        deleteVariable,
        clearData,

        editVaraibleModalRef,
        closeModal,
        showModal,
      };
    },
  });
</script>
