<template>
  <button type="button" class="btn btn-primary" @click="showModal()"><font-awesome-icon icon="file-download" /></button>

  <!-- Generate Dokument Modal -->
  <div
    class="modal fade"
    :id="'downloadVertragDocumentModal-' + dokument.id"
    tabindex="-1"
    aria-labelledby="downloadVertragDocumentModal"
    aria-hidden="true"
    ref="downloadVertragDocumentModalRef"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header bg-primary py-2">
          <strong class="modal-titel my-auto">Druckvorlage auswählen</strong>

          <button type="button" class="btn-close btn-sm text-white" @click="closeModal()"></button>
        </div>

        <div class="modal-body modal-body-min-height" id="downloadVertragDocumentModalBody">
          <LoadingSpinner v-if="loading" />

          <div v-else>
            <div v-if="errorMsg != ''" class="alert alert-danger mb-3">
              Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.
              {{ errorMsg }}
            </div>

            <div class="row mb-3">
              <div class="col-4 offset-4">
                <div class="input-group input-group-sm my-auto">
                  <input v-model.trim="searchString" type="search" class="form-control" id="searchInput" placeholder="Suche..." />
                  <span class="input-group-text">
                    <font-awesome-icon icon="magnifying-glass" class="p-1" />
                  </span>
                </div>
              </div>
            </div>

            <div class="row mx-1 mb-2 bg-white shadow-sm">
              <div class="col-3 py-2 table-col">
                <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                  <strong>Titel</strong>

                  <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'titelasc'" class="text-secondary table-sort-icon" />
                  <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'titeldesc'" class="text-secondary table-sort-icon" />

                  <ul class="dropdown-menu m-0 ms-1 p-0 rounded-0">
                    <li class="dropdown-item select-sort" @click="sortBy('titelasc')">
                      <div class="row">
                        <div class="col-2">
                          <font-awesome-icon icon="check" v-show="sortByValue == 'titelasc'" />
                        </div>
                        <div class="col">A - Z</div>
                      </div>
                    </li>
                    <li class="dropdown-item select-sort" @click="sortBy('titeldesc')">
                      <div class="row">
                        <div class="col-2">
                          <font-awesome-icon icon="check" v-show="sortByValue == 'titeldesc'" />
                        </div>
                        <div class="col">Z - A</div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-3 py-2 table-col">
                <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                  <strong>Kategorie</strong>

                  <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'kategorieasc'" class="text-secondary table-sort-icon" />
                  <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'kategoriedesc'" class="text-secondary table-sort-icon" />

                  <ul class="dropdown-menu m-0 ms-1 p-0 rounded-0">
                    <li class="dropdown-item select-sort" @click="sortBy('kategorieasc')">
                      <div class="row">
                        <div class="col-2">
                          <font-awesome-icon icon="check" v-show="sortByValue == 'kategorieasc'" />
                        </div>
                        <div class="col">A - Z</div>
                      </div>
                    </li>
                    <li class="dropdown-item select-sort" @click="sortBy('kategoriedesc')">
                      <div class="row">
                        <div class="col-2">
                          <font-awesome-icon icon="check" v-show="sortByValue == 'kategoriedesc'" />
                        </div>
                        <div class="col">Z - A</div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-6 py-2 table-col">
                <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                  <strong>Datei</strong>

                  <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'dateiasc'" class="text-secondary table-sort-icon" />
                  <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'dateidesc'" class="text-secondary table-sort-icon" />

                  <ul class="dropdown-menu m-0 ms-1 p-0 rounded-0">
                    <li class="dropdown-item select-sort" @click="sortBy('dateiasc')">
                      <div class="row">
                        <div class="col-2">
                          <font-awesome-icon icon="check" v-show="sortByValue == 'dateiasc'" />
                        </div>
                        <div class="col">A - Z</div>
                      </div>
                    </li>
                    <li class="dropdown-item select-sort" @click="sortBy('dateidesc')">
                      <div class="row">
                        <div class="col-2">
                          <font-awesome-icon icon="check" v-show="sortByValue == 'dateidesc'" />
                        </div>
                        <div class="col">Z - A</div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div v-if="sortedDokumente.length == 0" class="alert alert-info mb-3">Es wurden keine Word-Vorlagen gefunden.</div>

            <div v-for="dokument in sortedDokumente" :key="dokument.id">
              <div class="row bg-white shadow-sm mx-1 mb-1 py-2 select-word-vorlage" @click="generateDocument(dokument.id)">
                <div class="col-3 my-auto">{{ dokument.titel }}</div>
                <div class="col-3 my-auto">{{ dokument.kategorie }}</div>
                <div class="col-6 my-auto">{{ dokument.fileName }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType, computed, ref } from 'vue';
  import { Modal } from 'bootstrap';
  import { useStore } from 'vuex';

  import LoadingSpinner from '../LoadingSpinner.vue';

  import { ActionTypes as VertragActionTypes } from '@/store/modules/Vertraege/actions';

  import { GenerateWordDocumentRequest, VertragDokument } from '@/models/VertragModels';
  import { Druckvorlage } from '@/models/DruckvorlageModels';

  export default defineComponent({
    name: 'DownloadVertragDocumentModal',

    components: {
      LoadingSpinner,
    },

    props: {
      dokument: {
        type: Object as PropType<VertragDokument>,
        required: true,
      },
      titel: {
        type: String as PropType<string>,
        required: true,
      },
    },

    setup(props) {
      const store = useStore();

      const loading = computed(() => store.getters.status.createVertragLoading);
      const errorMsg = computed(() => store.getters.status.vertragErrorMsg);

      const searchString = ref('');

      const sortByValue = ref('titelasc');
      function sortBy(param: string) {
        sortByValue.value = param;
      }

      const druckvorlagen = computed(() => store.getters.druckvorlagen);
      const sortedDokumente = computed(() => {
        return druckvorlagen.value
          .filter((vorlage: Druckvorlage) => {
            return (
              vorlage.fileName.trim() != '' &&
              vorlage.fileName.trim().toLowerCase().includes('.dotx') &&
              (vorlage.titel.trim().toLowerCase().includes(searchString.value.trim().toLowerCase()) ||
                vorlage.kategorie.trim().toLowerCase().includes(searchString.value.trim().toLowerCase()) ||
                vorlage.fileName.trim().toLowerCase().includes(searchString.value.trim().toLowerCase()))
            );
          })
          .sort((a: Druckvorlage, b: Druckvorlage) => {
            if (sortByValue.value == 'titelasc' || sortByValue.value == 'titeldesc') {
              var titelComparison = a.titel.trim().toLowerCase().localeCompare(b.titel.trim().toLowerCase());
              if (sortByValue.value == 'titeldesc') {
                titelComparison = -1 * titelComparison;
              }

              if (titelComparison == 0) {
                var kategorieComparison = a.kategorie.trim().toLowerCase().localeCompare(b.kategorie.trim().toLowerCase());
                return kategorieComparison;
              }

              return titelComparison;
            }

            if (sortByValue.value == 'kategorieasc' || sortByValue.value == 'kategoriedesc') {
              var kategorieComparison = a.kategorie.trim().toLowerCase().localeCompare(b.kategorie.trim().toLowerCase());
              if (sortByValue.value == 'kategoriedesc') {
                kategorieComparison = -1 * kategorieComparison;
              }

              if (kategorieComparison == 0) {
                var titelComparison = a.titel.trim().toLowerCase().localeCompare(b.titel.trim().toLowerCase());
                return titelComparison;
              }

              return kategorieComparison;
            }

            if (sortByValue.value == 'dateiasc' || sortByValue.value == 'dateidesc') {
              var dateiComparison = a.fileName.trim().toLowerCase().localeCompare(b.fileName.trim().toLowerCase());
              if (sortByValue.value == 'dateidesc') {
                dateiComparison = -1 * dateiComparison;
              }

              if (dateiComparison == 0) {
                var titelComparison = a.titel.trim().toLowerCase().localeCompare(b.titel.trim().toLowerCase());
                return titelComparison;
              }

              return dateiComparison;
            }

            return 0;
          });
      });

      async function generateDocument(documentId: number) {
        await store
          .dispatch(
            VertragActionTypes.GenerateWordDocument,
            new GenerateWordDocumentRequest(props.titel, props.dokument.data, documentId, props.dokument.fileName + '.docx')
          )
          .then(() => {
            closeModal();
          });
      }

      const downloadVertragDocumentModalRef = ref();
      function closeModal() {
        Modal.getInstance(downloadVertragDocumentModalRef.value)?.hide();
      }
      function showModal() {
        if (downloadVertragDocumentModalRef.value) {
          new Modal(downloadVertragDocumentModalRef.value).show();
        }
      }

      return {
        store,
        loading,
        errorMsg,

        searchString,
        sortByValue,
        sortBy,
        sortedDokumente,

        generateDocument,

        downloadVertragDocumentModalRef,
        closeModal,
        showModal,
      };
    },
  });
</script>

<style scoped>
  .select-word-vorlage:hover {
    cursor: pointer;
    background-color: #fff4af !important;
  }

  .modal-body {
    background: rgb(242, 243, 248);
  }

  .modal-body-min-height {
    min-height: 400px;
  }

  .table-col:hover {
    background: rgb(216, 217, 222);
    cursor: pointer;
  }

  .table-sort-icon {
    font-size: 0.7em;
  }

  .select-sort:hover {
    cursor: pointer;
  }
</style>
